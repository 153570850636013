<template>
    <section class="under-construction-section">
        <div class="inner-wrapper">
            <div class="container">
                <div class="column-wrapper fullwidth">
                    <div class="column">
                        <div class="title-wrapper">
                            <img class="logo" alt="LEAD Hockey - logo" src="@/assets/svgs/logo-icon.svg" />

                            <h2 class="column-title">Streampac.io<br />TEST</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section v-if="activeDeveloperLogin" class="developer-login-popup global-popup">
        <div class="container">
            <div class="column-wrapper">
                <div class="column login-form">
                    <button class="close-overlay-btn" @click="closeDeveloperLogin">
                        X
                    </button>
                    <div class="title-wrapper">
                        <h2 class="column-title">Developer login</h2>
                        <p class="column-subtitle">
                            Voer je gegevens in om in te loggen.
                        </p>
                    </div>
                    <UnderConstructionLogin />
                </div>
            </div>
        </div>
    </section>
    <button class="develop-login-btn" @click="loginWithClick()"></button>
</template>

<script>
//@import component
import UnderConstructionLogin from "@/components/Forms/UnderConstructionLogin";

export default {
    name: "UnderConstructionPage",
    data() {
        return {
            activeDeveloperLogin: false,
        };
    },
    components: {
        UnderConstructionLogin,
    },
    methods: {
        showDeveloperLogin() {
            this.activeDeveloperLogin = true;
        },
        closeDeveloperLogin() {
            this.activeDeveloperLogin = false;
        },
        loginWithClick() {
            this.$store.commit("removeUnderConstruction");

            //24h validated
            this.setCookie("under-construction-validated", "yes", 1);
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
