<template>
    <form autocomplete="off" @submit.prevent="handleLogin()">
        <div v-if="loginError" class="error-msg">
            <p>Your username and/or password is not correct</p>
        </div>
        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-email">E-mail</label>
                <div class="input-wrapper">
                    <input type="email" id="user-email" name="username" placeholder="E-mail" @keyup="changeInput()"
                        v-model="user.username" :class="{ error: loginError }" autocomplete="off" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-password">Password</label>
                <div class="input-wrapper">
                    <input type="password" id="user-password" name="password" placeholder="Wachtwoord"
                        @keyup="changeInput()" v-model="user.password" :class="{ error: loginError }" autocomplete="off" />
                </div>
            </span>
        </div>

        <button class="submit" data-txt="Login">Login</button>
    </form>
</template>

<script>
export default {
    name: "UnderConstructionLoginForm",
    components: {},
    data() {
        return {
            user: {
                username: "",
                password: "",
            },
            loginError: false,
        };
    },
    methods: {
        changeInput() {
            this.loginError = false;
        },
        handleLogin() {
            if (
                this.user.username == "test@streampac.io" &&
                this.user.password == "g!j0_42FJ$E3s"
            ) {
                this.$store.commit("removeUnderConstruction");
                this.loginError = false;

                //24h validated
                this.setCookie("under-construction-validated", "yes", 1);
            } else {
                this.loginError = true;
                this.user.username = "";
                this.user.password = "";
            }
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    },
};
</script>
